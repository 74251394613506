import { PageEnum, RouterNameEnum } from '@/types/enums/pageEnum'
import type { RouteRecordRaw } from 'vue-router'
export const routesList: RouteRecordRaw[] = [
  {
    path: PageEnum.BASE_HOME,
    name: RouterNameEnum.BASE_HOME,
    meta: {
      title: '首页',
      desc: '首页',
      layout: null,
    },
    component: () => import('@/views/home/index.vue'),
  },
  // 登录
  {
    path: PageEnum.BASE_LOGIN,
    name: RouterNameEnum.BASE_LOGIN,
    meta: {
      layout: 'loginLayout',
    },
    component: () => import('@/views/login/index.vue'),
  },
  {
    path: PageEnum.MERCHANT_SETTING,
    name: RouterNameEnum.MERCHANT_SETTING,
    meta: {
      layout: 'dashboardLayout',
    },
    component: () => import('@/views/merchantSetting/index.vue'),
  },
  {
    path: PageEnum.USER_SETTING,
    name: RouterNameEnum.USER_SETTING,
    meta: {
      layout: 'dashboardLayout',
    },
    component: () => import('@/views/userSetting/index.vue'),
  },
  {
    path: PageEnum.INVITE_MEMBER,
    name: RouterNameEnum.INVITE_MEMBER,
    meta: {
      layout: 'loginLayout',
    },
    component: () => import('@/views/inviteMember/index.vue'),
  },
  {
    path: PageEnum.ERROR_PAGE,
    name: RouterNameEnum.ERROR_PAGE,
    component: () => import('@/views/exception/index.vue'),
  },
  {
    path: PageEnum.SERVICE_PRIVACY,
    name: RouterNameEnum.SERVICE_PRIVACY,
    component: () => import('@/views/service/privacy/index.vue'),
  },
  {
    path: PageEnum.SERVICE_TERMS,
    name: RouterNameEnum.SERVICE_TERMS,
    component: () => import('@/views/service/terms/index.vue'),
  },
  {
    path: PageEnum.SERVICE_OLD_PRIVACY,
    name: RouterNameEnum.SERVICE_OLD_PRIVACY,
    component: () => import('@/views/service/old/privacy.vue'),
  },
  {
    path: PageEnum.SERVICE_OLD_TERMS,
    name: RouterNameEnum.SERVICE_OLD_TERMS,
    component: () => import('@/views/service/old/terms.vue'),
  },
]

export const routesWhiteList: string[] = [
  PageEnum.BASE_HOME,
  PageEnum.RESET_PASSWORD_PAGE,
  PageEnum.BASE_LOGIN,
  PageEnum.SERVICE_PRIVACY,
  PageEnum.SERVICE_TERMS,
  PageEnum.INVITE_MEMBER,
  PageEnum.SERVICE_OLD_PRIVACY,
  PageEnum.SERVICE_OLD_TERMS,
]
