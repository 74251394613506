export enum PageEnum {
  BASE_HOME = '/', // 官网首页
  BASE_LOGIN = '/login', // 登录
  ERROR_PAGE = '/exception',
  ERROR_LOG_PAGE = '/error-log/list',
  RESET_PASSWORD_PAGE = '/resetpassword', // 重置密码页面
  SERVICE_PRIVACY = '/service/privacy', // 协议
  SERVICE_TERMS = '/service/terms',
  SERVICE_OLD_PRIVACY = '/service/old/privacy',
  SERVICE_OLD_TERMS = '/service/old/terms',
  BASE_DASHBOARD = '/dashboard', // 首页
  TRANSACTION = '/transaction', // 交易
  BUSINESS = '/business', // 交易明细
  BUSINESS_DETAIL = '/business/detail/:id',
  BUSINESS_DELIVERY = '/business/delivery/:id',
  CHARGEBACK = '/chargeback', // 退款页面
  BILL_REISSUE = '/billReissue', // 漏单补发
  BILL = '/bill', // 订单明细
  BILL_DETAIL = '/bill/detail/:id', // 订单详情
  GIVEAWAY_RECORD = '/giveawayRecord', // 赠品记录
  DATA_SEARCH = '/dataSearch', // 用户数据查询
  DATA_ANALYZE = '/dataAnalyze', // 付费数据
  REFUND_ANALYZE = '/refundAnalyze', // 退款数据
  PAY_USERS = '/payingUsers', // 当日数据
  CONVERSION_RATE = '/conversionRate', // 转化率
  PAYMENT_ACTIVITIES = '/paymentActivities', // 付费活跃数据
  PROP_ANALYSIS = '/propAnalysis', // 道具购买数据
  NEW_DATA_ANALYZE = '/newDataAnalyze', // 破冰用户数据
  USER_PAY_TOP = '/userPayTop', // 用户付费排行
  GOODS_SALES_TOP = '/goodsSalesTop', // 道具用户购买排行
  ANALYZE = '/analyze', // 运营活动数据
  ACTIVITIES_DATA = '/activitiesData', // 活动数据
  ACTIVITIES_DATA_DETAIL = '/activitiesData/:id',
  VIP_DATA = '/vipData', // 会员中心数据
  SIGN_IN_DATA = '/signInData', // 每日签到数据
  INTEG_TASK_DATA = '/integralData/task', // 积分任务完成数据
  INTEG_GOODS_DATA = '/integralData/goods', // 积分商品兑换数据
  PROMOTER_DATA = '/promoterData', // 分享拉新数据
  CHANNEL_DATA = '/channelData', // 渠道数据
  DATA_OVERVIEW = '/dataOverview', // 数据总览
  RATE_FLOAT = '/rateFloat', // 汇率波动
  CHANNEL_ANALYZE = '/channelAnalyse', // 渠道监控
  CHANNEL_OVERVIEW = '/channelOverview', // 渠道总览
  AREA_ANALYZE = '/areaAnalyze', // 地区监控
  AREA_OVERVIEW = '/areaOverview', // 地区总览
  CURRENCY_ANALYZE = '/currencyAnalyze', // 货币监控
  CURRENCY_OVERVIEW = '/currencyOverview', // 货币总览
  PAY_CHANNEL = '/payChannel', // 渠道付费数据
  AUTH_MANAGEMENT = '/authManagement', // 用户和权限
  // AUTH_MANAGEMENT_INDEX = '/authManagementIndex',
  AUTH_USER_DETAIL = '/authManagement/user/:id',
  AUTH_ROLE_DETAIL = '/authManagement/role/:id',
  OPERATION_RECORD = '/operationRecord', // 操作日志
  // MERCHANT_SETTLEMENT = '/merchantSettlement',
  MERCHANT_SETTING = '/merchantSetting', // 公司设置页面
  USER_SETTING = '/userSetting', // 用户设置页面
  INVITE_MEMBER = '/invite', // 邀请用户欢迎页面

  // 应用内路由
  APPS = '/apps', // 应用首页
  SHOP = '/apps/index', // 商店
  LOGIN_MANAGE = '/apps/loginManage', // 登录管理器
  SITE_DESIGNER = '/siteDesigner', // 建站器
  GOODGROUP = '/apps/goodGroup', // 商品组
  GOODGS = '/apps/goods', // 商品列表
  APP_INFO = '/apps/appInfo', // 应用设置
  DOOGDETAIL = '/apps/goodDetail', // 商品详情
  DEMO_TABLE = '/demo/dynamicTable', // 动态列表demo
  // 应用相关页面路由
  PROJECT_MANAGE_HOME = '/projectManage', // 项目管理首页
  PROJECT_ALL = '/projectManage/projectAll',
  PROJECT = '/projectManage/project', // 单个app渲染
  // PROJECT_SETTING = '/projectManage/project/:projectId/setting', // app 设置页
  PROJECT_INDEX = '/projectManage/project/:projectId',
  PROJECT_GOOD = '/projectManage/project/:projectId/good',
  PROJECT_LANGUAGE = '/projectManage/project/:projectId/storeLanguage',
  PROJECT_CURRENCY = '/projectManage/project/:projectId/currency',
  PROJECT_CLASS = '/projectManage/project/:projectId/class',
  PROJECT_CLASS_DETAIL = '/projectManage/project/:projectId/class/:goodId',
  PROJECT_ACTIVITY = '/projectManage/project/:projectId/activity',
  PROJECT_ACTIVITY_DETAIL = '/projectManage/project/:projectId/activity/detail/:id',
  PROJECT_FACE_IMG = '/projectManage/project/:projectId/faceImg',
  PROJECT_PAYS = '/projectManage/project/:projectId/pay',
  PROJECT_PAYS_METHODS = '/projectManage/project/:projectId/pay/methods',
  PROJECT_PAYS_SORT = '/projectManage/project/:projectId/pay/sort',
  PROJECT_PAY_DISCOUNT = '/projectManage/project/:projectId/pay/discount',
  PROJECT_SITE_BUILD = '/projectManage/project/:projectId/siteBuild', // site builder列表页
  PROJECT_SITE_BUILD_EDITOR = '/projectManage/project/:projectId/siteBuild/editor/:domain', // site builder建站器
  PROJECT_SITE_BUILD_PREVIEW = '/projectManage/project/:projectId/siteBuild/preview/:domain/:lang', // site builder预览页
  PROJECT_USER_GROUP = '/projectManage/project/:projectId/userGroup', // 用户群体
  PROJECT_SETTING = '/projectManage/project/:projectId/setting',
  PROJECT_GENERAL_SETTING = '/projectManage/project/:projectId/generalSetting',
  PROJECT_WEBHOOKS_SETTING = '/projectManage/project/:projectId/webhooksSetting',
  PROJECT_PRICE_UPDATE = '/projectManage/project/:projectId/priceUpdate', // 自动更新价格
  PROJECT_PRICE_TEMPLATE = '/projectManage/project/:projectId/pricetemplate', // 定价模版
  PROJECT_RISK_MANAGER = '/projectManage/project/:projectId/risk', // 风险管理
  PROJECT_RISK_SETTING = '/projectManage/project/:projectId/riskSetting', // 风险设置
  PROJECT_BLACKLIST = '/projectManage/project/:projectId/blacklist', // 退款黑名单
  REFUSE_BLACKLIST = '/projectManage/project/:projectId/refuse_blacklist', // 拒付黑名单
  EXTERNAL = '/projectManage/project/:projectId/external', // 外部渠道
  EXTERNAL_CHANNEL = '/projectManage/project/:projectId/external-channel', // 外部渠道
  EXTERNAL_CHANNEL_DETAIL = '/projectManage/project/:projectId/external-channel/:id', // 外部渠道详情
  EXTERNAL_CHANNEL_PRICE = '/projectManage/project/:projectId/external-channel-price', // 渠道价格对比
  EXTERNAL_CHANNEL_IMPORT = '/projectManage/project/:projectId/external-channel-import', // 渠道商品批量导入记录
  MSG_PUSH = '/projectManage/project/:projectId/msg-push', // 消息推送
  ASSET_LIBRARY = '/projectManage/project/:projectId/asset-library', //图片库管理
  VIP_STORE = '/projectManage/project/:projectId/vip-store', // 会员中心
  VIP_STORE_SETTING = '/projectManage/project/:projectId/vip-store-setting', // 会员中心-权益配置
  VIP_VARIABLES_SETTING = '/projectManage/project/:projectId/vip-variables-setting', // 会员中心-参数配置
  PROMOTION_SHARE = '/projectManage/project/:projectId/promotion-share', // 分享拉新
  PROJECT_LANG = '/projectManage/project/:projectId/lang', // 多语言管理
  AUTO_LOGIN_LINK = '/projectManage/project/:projectId/auto-login-link', // 自动登录链接
  AUTO_LOGIN_LINK_INDEX = '/projectManage/project/:projectId/auto-login-link/index', // 自动登录链接
  AUTO_LOGIN_LINK_DETAIL = '/projectManage/project/:projectId/auto-login-link/:id', // 自动登录链接
  PROJECT_INTEGRAL = '/projectManage/project/:projectId/integral', // 积分
  PROJECT_INTEGRAL_GOODS = '/projectManage/project/:projectId/integral/goods', // 积分商品
  PROJECT_INTEGRAL_TASKS = '/projectManage/project/:projectId/integral/tasks', // 积分任务
  PROJECT_WELFARE = '/projectManage/project/:projectId/welfare', // 福利中心
}

export enum RouterNameEnum {
  BASE_HOME = 'BASE_HOME',
  BASE_LOGIN = 'BASE_LOGIN',
  BASE_DASHBOARD = 'BASE_DASHBOARD',
  BILL = 'BILL',
  BILL_DETAIL = 'BILL_DETAIL',
  BILL_REISSUE = 'BILL_REISSUE',
  TRANSACTION = 'TRANSACTION',
  BUSINESS = 'BUSINESS',
  BUSINESS_DETAIL = 'BUSINESS_DETAIL',
  BUSINESS_DELIVERY = 'BUSINESS_DELIVERY',
  ERROR_PAGE = 'ERROR_PAGE',
  ERROR_LOG_PAGE = 'ERROR_LOG_PAGE',
  RESET_PASSWORD_PAGE = 'RESET_PASSWORD_PAGE',
  APPS = 'APPS',
  SHOP = 'SHOP',
  LOGIN_MANAGE = 'LOGIN_MANAGE',
  SITE_DESIGNER = 'SITE_DESIGNER',
  GOODGROUP = 'GOODGROUP',
  GOODGS = 'GOODGS',
  APP_INFO = 'APP_INFO',
  DOOGDETAIL = 'DOOGDETAIL',
  DEMO_TABLE = 'DEMO_TABLE',
  SERVICE_PRIVACY = 'SERVICE_PRIVACY',
  SERVICE_TERMS = 'SERVICE_TERMS',
  SERVICE_OLD_PRIVACY = 'SERVICE_OLD_PRIVACY',
  SERVICE_OLD_TERMS = 'SERVICE_OLD_TERMS',
  ANALYZE = 'ANALYZE',
  DATA_SEARCH = 'DATA_SEARCH',
  CHANNEL_DATA = 'CHANNEL_DATA',
  DATA_OVERVIEW = 'DATA_OVERVIEW',
  NEW_DATA_ANALYZE = 'NEW_DATA_ANALYZE',
  DATA_ANALYZE = 'DATA_ANALYZE',
  REFUND_ANALYZE = 'REFUND_ANALYZE',
  PAY_USERS = 'PAY_USERS',
  PAY_CHANNEL = 'PAY_CHANNEL',
  CONVERSION_RATE = 'CONVERSION_RATE',
  RATE_FLOAT = 'RATE_FLOAT',
  ACTIVITIES_DATA = 'ACTIVITIES_DATA',
  ACTIVITIES_DATA_DETAIL = 'ACTIVITIES_DATA_DETAIL',
  PROP_ANALYSIS = 'PROP_ANALYSIS',
  AUTH_MANAGEMENT = 'AUTH_MANAGEMENT',
  // AUTH_MANAGEMENT_INDEX = 'AUTH_MANAGEMENT_INDEX',
  AUTH_USER_DETAIL = 'AUTH_USER_DETAIL',
  AUTH_ROLE_DETAIL = 'AUTH_ROLE_DETAIL',
  CHANNEL_ANALYZE = 'CHANNEL_ANALYZE',
  CHANNEL_OVERVIEW = 'CHANNEL_OVERVIEW',
  AREA_ANALYZE = 'AREA_ANALYZE',
  AREA_OVERVIEW = 'AREA_OVERVIEW',
  CURRENCY_ANALYZE = 'CURRENCY_ANALYZE',
  CURRENCY_OVERVIEW = 'CURRENCY_OVERVIEW',
  PAYMENT_ACTIVITIES = 'PAYMENT_ACTIVITIES',
  USER_PAY_TOP = 'USER_PAY_TOP',
  GOODS_SALES_TOP = 'GOODS_SALES_TOP',
  VIP_DATA = 'VIP_DATA',
  SIGN_IN_DATA = 'SIGN_IN_DATA',
  INTEG_TASK_DATA = 'INTEG_TASK_DATA',
  INTEG_GOODS_DATA = 'INTEG_GOODS_DATA',
  PROMOTER_DATA = 'PROMOTER_DATA',
  CHARGEBACK = 'CHARGEBACK',
  // MERCHANT_SETTLEMENT = 'MERCHANT_SETTLEMENT',
  MERCHANT_SETTING = 'MERCHANT_SETTING',
  USER_SETTING = 'USER_SETTING',
  INVITE_MEMBER = 'INVITE_MEMBER',
  OPERATION_RECORD = 'OPERATION_RECORD',
  GIVEAWAY_RECORD = 'GIVEAWAY_RECORD',
  PROJECT = 'PROJECT',
  PROJECT_MANAGE_HOME = 'PROJECT_MANAGE_HOME',
  PROJECT_SETTING = 'PROJECT_SETTING',
  PROJECT_INDEX = 'PROJECT_INDEX',
  PROJECT_SITE_BUILD = 'PROJECT_SITE_BUILD',
  PROJECT_SITE_BUILD_EDITOR = 'PROJECT_SITE_BUILD_EDITOR',
  PROJECT_SITE_BUILD_PREVIEW = 'PROJECT_SITE_BUILD_PREVIEW',
  PROJECT_ALL = 'PROJECT_ALL',
  PROJECT_GOOD = 'PROJECT_GOOD',
  PROJECT_LANGUAGE = 'PROJECT_LANGUAGE',
  PROJECT_CURRENCY = 'PROJECT_CURRENCY',
  PROJECT_CLASS = 'PROJECT_CLASS',
  PROJECT_CLASS_DETAIL = 'PROJECT_CLASS_DETAIL',
  PROJECT_ACTIVITY = 'PROJECT_ACTIVITY',
  PROJECT_ACTIVITY_DETAIL = 'PROJECT_ACTIVITY_DETAIL',
  PROJECT_FACE_IMG = 'PROJECT_FACE_IMG',
  PROJECT_PAYS = 'PROJECT_PAYS',
  PROJECT_PAYS_METHODS = 'PROJECT_PAYS_METHODS',
  PROJECT_PAYS_SORT = 'PROJECT_PAYS_SORT',
  PROJECT_PAY_DISCOUNT = 'PROJECT_PAY_DISCOUNT',
  PROJECT_USER_GROUP = 'PROJECT_USER_GROUP',
  PROJECT_GENERAL_SETTING = 'PROJECT_GENERAL_SETTING',
  PROJECT_WEBHOOKS_SETTING = 'PROJECT_WEBHOOKS_SETTING',
  PROJECT_PRICE_UPDATE = 'PROJECT_PRICE_UPDATE',
  PROJECT_PRICE_TEMPLATE = 'PROJECT_PRICE_TEMPLATE', // 定价模版
  PROJECT_PRICE_TEMPLATE_DETAIL = 'PROJECT_PRICE_TEMPLATE_DETAIL', // 定价模版
  PROJECT_RISK_MANAGER = 'PROJECT_RISK_MANAGER', // 风险管理
  PROJECT_RISK_SETTING = 'PROJECT_RISK_SETTING', // 风险设置
  PROJECT_BLACKLIST = 'PROJECT_BLACKLIST', // 退款黑名单
  REFUSE_BLACKLIST = 'REFUSE_BLACKLIST', // 拒付黑名单
  EXTERNAL = 'EXTERNAL', // 外部渠道
  EXTERNAL_CHANNEL = 'EXTERNAL_CHANNEL', // 外部渠道
  EXTERNAL_CHANNEL_DETAIL = 'EXTERNAL_CHANNEL_DETAIL', // 外部渠道详情
  EXTERNAL_CHANNEL_PRICE = 'EXTERNAL_CHANNEL_PRICE', // 渠道价格对比
  EXTERNAL_CHANNEL_IMPORT = 'EXTERNAL_CHANNEL_IMPORT', // 渠道商品批量导入记录
  MSG_PUSH = 'MSG_PUSH', // 消息推送
  ASSET_LIBRARY = 'ASSET_LIBRARY', // 图片库管理
  VIP_STORE = 'VIP_STORE', // 会员中心
  VIP_STORE_SETTING = 'VIP_STORE_SETTING', // 会员中心-权益配置
  VIP_VARIABLES_SETTING = 'VIP_VARIABLES_SETTING', // 会员中心-参数配置
  PROMOTION_SHARE = 'PROMOTION_SHARE', // 分享拉新
  PROJECT_LANG = 'PROJECT_LANG', // 多语言管理
  AUTO_LOGIN_LINK = 'AUTO_LOGIN_LINK', // 自动登录链接
  AUTO_LOGIN_LINK_INDEX = 'AUTO_LOGIN_LINK_INDEX', // 自动登录链接
  AUTO_LOGIN_LINK_DETAIL = 'AUTO_LOGIN_LINK_DETAIL', // 自动登录链接
  PROJECT_INTEGRAL = 'PROJECT_INTEGRAL', // 积分
  PROJECT_INTEGRAL_GOODS = 'PROJECT_INTEGRAL_GOODS', // 积分商品
  PROJECT_INTEGRAL_TASKS = 'PROJECT_INTEGRAL_TASKS', // 积分任务
  PROJECT_WELFARE = 'PROJECT_WELFARE', // 福利中心
}
